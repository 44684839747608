import { QaroniEnumInformation } from '../qaroni-enum-information/qaroni-enum-information';

export enum LanguageEnum {
  default = 'gl',
  es = 'es',
  gl = 'gl',
}

export const LanguageEnumArray = [LanguageEnum.es, LanguageEnum.gl];

export const LanguageEnumInfo: QaroniEnumInformation<LanguageEnum> = {
  [LanguageEnum.default]: {
    name: 'gl',
  },
  [LanguageEnum.es]: {
    name: 'Español',
  },
};
